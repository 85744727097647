import { RxCollection } from 'rxdb';
import {
  toTypedRxJsonSchema,
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxJsonSchema,
} from 'rxdb';
import { clinicalTagSchemaLiteral } from './ClinicalTag.schema';

export const userTagSchemaTyped = toTypedRxJsonSchema(clinicalTagSchemaLiteral);

type ClinicalTagDocumentType = ExtractDocumentTypeFromTypedRxJsonSchema<
  typeof userTagSchemaTyped
>;

export const ClinicalTagDocumentSchema: RxJsonSchema<ClinicalTagDocumentType> =
  clinicalTagSchemaLiteral;

export type ClinicalTagDocumentCollection =
  RxCollection<ClinicalTagDocumentType>;
