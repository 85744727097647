import { useEffect, useMemo, useState } from 'react';
import { ulid } from 'ulid';
import { useSearchParams } from 'react-router-dom';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { matchSorter } from 'match-sorter';
import {
  collections,
  useFirestoreDb,
} from '../components/providers/FirebaseProvider';
import { doc, onSnapshot, query, setDoc } from 'firebase/firestore';
import { VoteDocument } from '../models/vote-document/VoteDocument.type';
import { useUser } from '../components/providers/UserProvider';
import assemblies from '../assets/assemblies.json';
import { SearchBar } from './SearchBar';
import { QueryStatus } from './TimelineTab';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { useDebounceEffect } from '../components/settings/EditUserModalForm';

type Option = { name: string; text: string; votes: number };

export const usePoll = (
  allVotes: VoteDocument[],
  pollName: string,
  options: Option[]
) => {
  const [votes, setVotes] = useState<VoteDocument[]>([]);
  const user = useUser();
  const db = useFirestoreDb();

  useEffect(() => {
    const pollNameVotes = allVotes.filter(
      (vote) => vote.poll_name === pollName
    );
    setVotes(pollNameVotes);
  }, [allVotes, pollName]);

  const optionsWithVotes = useMemo(() => {
    return options.map((option) => {
      const votesForOption = votes.filter((pollNameVote) => {
        if (
          Array.isArray(pollNameVote.selected_options) &&
          pollNameVote.selected_options.length
        ) {
          return pollNameVote.selected_options.includes(option.name);
        }
        return pollNameVote.selected_option === option.name;
      });
      return { ...option, votes: votesForOption.length };
    });
  }, [votes, options]);

  const myVotes = useMemo(() => {
    const _myVotes = votes.filter((vote) => vote.uid === user?.id);
    return _myVotes;
  }, [votes, user]);

  const onVote = async (selectedOption: string, selectedOptions: string[]) => {
    const id = myVotes.length ? myVotes[0].id : ulid();
    const data = {
      id: id,
      uid: user.id,
      user_id: user.id,
      selected_option: selectedOption,
      selected_options: selectedOptions,
      poll_name: pollName,
    };
    const res = await setDoc(doc(collections.votes(db), id), data);
    const analytics = getAnalytics();
    logEvent(analytics, 'on_vote', { ...data });
    console.log('res', res);
  };

  const data = {
    votes,
    optionsWithVotes,
    myVotes,
    onVote,
  };
  return data;
};

const AssemblyPoll = ({
  allvotes,
  name,
  loksabha,
  options,
}: {
  allvotes: VoteDocument[];
  name: string;
  options: Option[];
  loksabha: string;
}) => {
  const { optionsWithVotes, onVote, myVotes } = usePoll(
    allvotes,
    `${name} Assembly`,
    options
  );

  const totalVotes = optionsWithVotes.reduce(
    (acc, option) => acc + option.votes,
    0
  );

  const myVote = myVotes[0];

  return (
    <div className="m-4 space-y-4 rounded-lg border border-gray-200 p-8">
      <h2 className="mb-4 text-lg font-semibold">
        {`Vote for ${name} Assembly (${loksabha})`}:
      </h2>

      <div className="flex flex-col gap-4">
        {optionsWithVotes.map((option) => (
          <div
            className="flex  flex-col items-center space-x-2"
            key={option.name}
          >
            <label
              className="flex w-full cursor-pointer items-center justify-between space-x-2"
              onChange={() => onVote(option.name, [])}
            >
              <div className="flex  items-center gap-2">
                {myVote?.selected_option != option['name'] ? (
                  <input
                    type="radio"
                    name="poll-option"
                    className="form-radio h-4 w-4 text-green-500"
                  />
                ) : (
                  <CheckCircleIcon
                    className="h-5 w-5 text-green-500"
                    aria-hidden="true"
                  />
                )}
                <span>{option.text}</span>
              </div>

              <div className="flex items-center space-x-2">
                <span className="vote-count mr-2 text-gray-600">
                  {totalVotes
                    ? `${((option.votes / totalVotes) * 100).toFixed(1)}%`
                    : '0 %'}
                </span>
                <div className="avatar h-6 w-6 rounded-full bg-gray-200"></div>
              </div>
            </label>

            <div className="progressbar-container mt-2 h-2 w-full bg-gray-300">
              <div
                className="progressbar h-full w-0 bg-green-500"
                style={{
                  width: totalVotes
                    ? `${(option.votes / totalVotes) * 100}%`
                    : '0',
                }}
              ></div>
            </div>
          </div>
        ))}
      </div>
      {/* </div> */}
    </div>
  );
};

const Polls = () => {
  const db = useFirestoreDb();
  const user = useUser();
  const [searchParams, setSearchParams] = useSearchParams();
  const [votes, setVotes] = useState<VoteDocument[]>([]);
  const [searchString, setSearchString] = useState(
    searchParams.get('search') || ''
  );

  useEffect(() => {
    const q = query(collections.votes(db));
    const unsub = onSnapshot(q, (items) => {
      if (items.empty) {
        return;
      }
      const docDataItems = items.docs.map((doc) => doc.data() as VoteDocument);
      setVotes(docDataItems);
    });
    return () => {
      console.log('unsubcribing votes');
      unsub();
    };
  }, [db]);

  const constituencies = useMemo(() => {
    if (!searchString) {
      return assemblies.constituencies;
    }
    return matchSorter(assemblies.constituencies, searchString, {
      keys: ['name', 'loksabha', 'district', 'tags'],
      threshold: matchSorter.rankings.NO_MATCH,
    });
  }, [searchString]);

  useDebounceEffect(
    () => {
      setSearchParams({ search: searchString });
      const analytics = getAnalytics();
      logEvent(analytics, 'search', { query: searchString });
    },
    500,
    [searchString]
  );

  return (
    <div
      className="grid h-full overflow-y-auto border-gray-200"
      style={{
        gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
        gridTemplateRows: 'repeat(auto-fit, 300px)',
      }}
    >
      <div
        style={{
          gridRow: 1,
          gridColumn: '1',
          backgroundColor: 'white',
          position: 'sticky',
          top: 0,
        }}
      >
        <img src="/assets/apvotes.png" alt="" />
        <div className="m-4">
          <SearchBar
            query={searchString}
            setQuery={setSearchString}
            status={QueryStatus.IDLE}
          ></SearchBar>
        </div>
      </div>
      {constituencies.map((assembly, i) => (
        <AssemblyPoll
          name={assembly.name}
          loksabha={assembly.loksabha}
          allvotes={votes}
          options={assembly.options}
          key={i}
        ></AssemblyPoll>
      ))}
    </div>
  );
};

export default Polls;
