import React, { useEffect } from 'react';
import { useFirebase } from '../components/providers/FirebaseProvider';
import {
  GoogleAuthProvider,
  User,
  browserSessionPersistence,
  getAuth,
  linkWithCredential,
  setPersistence,
} from 'firebase/auth';
import { useSignInWithGoogle } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router';
import { Routes } from '../Routes';
import { useUser } from '../components/providers/UserProvider';

const Login = () => {
  const firebase = useFirebase();
  const auth = getAuth(firebase);
  const navigate = useNavigate();
  const [signInWithGoogle, userCredentials, loading, error] =
    useSignInWithGoogle(auth);
  const user = useUser();

  // Handle login form submission
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
  };

  useEffect(() => {
    if (userCredentials && auth.currentUser && auth.currentUser.isAnonymous) {
      userCredentials.user.getIdToken().then((token) => {
        const credential = GoogleAuthProvider.credential(token);
        linkWithCredential(auth.currentUser as User, credential);
      });
    }
  }, [userCredentials, loading, error, auth.currentUser]);

  const handleGoogleLogin = async () => {
    setPersistence(auth, browserSessionPersistence);
    const user = await signInWithGoogle();
    console.log('google user', user);
    navigate(Routes.Timeline);
  };

  useEffect(() => {
    console.log('user in login', user);
    if (user && !user.is_anonymous) {
      navigate(Routes.Vote);
    }
  }, [user]);

  return (
    <div className="flex-end flex h-screen flex-col">
      <div className="flex flex-1 items-center justify-center bg-white">
        {/* Second row: Login form */}
        <div className="flex w-full max-w-md flex-col py-8 px-4">
          <h2 className="mb-4 text-center text-2xl font-bold">Login</h2>
          <form onSubmit={handleSubmit} className="flex-grow space-y-4">
            {/* Mobile/Email input */}
            <div>
              <label htmlFor="mobileEmail" className="mb-1 block">
                Mobile/Email
              </label>
              <input
                type="text"
                id="mobileEmail"
                className="w-full rounded border border-gray-300 py-2 px-3"
              />
            </div>
            {/* OTP/Password input */}
            <div>
              <label htmlFor="otpPassword" className="mb-1 block">
                OTP/Password
              </label>
              <input
                type="password"
                id="otpPassword"
                className="w-full rounded border border-gray-300 py-2 px-3"
              />
            </div>
            {/* Google sign-in button */}
            {/* Login button */}
            <button
              type="submit"
              className="bg-primary-600 hover:bg-primary-700 focus:ring-primary-500 w-full rounded py-2 px-3 text-white"
              style={{ flex: '0 0 200px' }}
            >
              Login
            </button>
            <p className="text-center">OR</p>
            <button
              type="button"
              className="focus:ring-primary-500 hover:bg-grey-700 w-full rounded border border-gray-600 bg-white py-2 px-3 text-black"
              style={{ flex: '0 0 200px' }}
              onClick={handleGoogleLogin}
            >
              Sign in with Google
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
