import '../theme/fonts.css';
import '../styles.css';

import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { Routes as AppRoutes } from '../Routes';
import { UserProvider } from '../components/providers/UserProvider';
import { NotificationProvider } from '../components/providers/NotificationProvider';
import { TabWrapper } from '../components/TabWrapper';
import { RxDbProvider } from '../components/providers/RxDbProvider';
import { ErrorBoundary } from '../components/ErrorBoundary';
import { UserPreferencesProvider } from '../components/providers/UserPreferencesProvider';
import { SyncJobProvider } from '../components/providers/SyncJobProvider';
import { FirebaseProvider } from '../components/providers/FirebaseProvider';
import Login from '../pages/Login';
import { ProtectedRoute } from '../components/ProtectedRoute';

export default function App() {
  return (
    <ErrorBoundary>
      <NotificationProvider>
        <FirebaseProvider>
          <RxDbProvider>
            <UserProvider>
              <Router>
                <Routes>
                  <Route path={AppRoutes.Login} element={<Login />}></Route>
                  <Route
                    path="*"
                    element={
                      <ProtectedRoute>
                        <UserPreferencesProvider>
                          <SyncJobProvider>
                            <TabWrapper />
                          </SyncJobProvider>
                        </UserPreferencesProvider>
                      </ProtectedRoute>
                    }
                  />
                </Routes>
              </Router>
            </UserProvider>
          </RxDbProvider>
        </FirebaseProvider>
      </NotificationProvider>
    </ErrorBoundary>
  );
}
