import { useEffect, useState } from 'react';
import { ConnectionDocument } from '../../models/connection-document/ConnectionDocument.type';
import { useRxDb } from '../providers/RxDbProvider';
import { RxDocument } from 'rxdb';
import { useUser } from '../providers/UserProvider';
import { collections, useFirestoreDb } from '../providers/FirebaseProvider';
import { DocumentSnapshot, onSnapshot, query, where } from 'firebase/firestore';

/**
 * Returns a subscription to a list of all the connection cards
 * @returns Array of RxDocument<ConnectionDocument>[]
 */
export function useConnectionCards() {
  const db = useRxDb(),
    firestoreDb = useFirestoreDb(),
    user = useUser(),
    [list, setList] = useState<DocumentSnapshot<ConnectionDocument>[]>();

  useEffect(() => {
    const q = query(
      collections.connectionDocuments(firestoreDb),
      where('user_id', '==', user.id)
    );
    const sub = onSnapshot(q, (items) => {
      setList(items.docs);
    });
    return () => {
      if (sub) {
        sub();
      }
    };
  }, [db.connection_documents, user.id]);

  return list;
}
