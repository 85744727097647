import {
  ConsentResponseV2,
  FIDataFetchResponseV2,
  Transaction,
} from '@mere/setu';
import Config from '../environments/config.json';
import fiuJson from '../assets/fiu.json';
import { Routes } from '../Routes';
import { ConnectionDocument } from '../models/connection-document/ConnectionDocument.type';
import { BundleEntry, MergedFhirResource } from 'fhir/r2';
import { ClinicalDocument } from '../models/clinical-document/ClinicalDocument.type';
import { mapDataToBundle, mapTransactionToClinicalDocument } from './AA';
import { Firestore, doc, updateDoc, writeBatch } from 'firebase/firestore';
import { collections } from '../components/providers/FirebaseProvider';

export async function getConsentUrl(fipId: string): Promise<string> {
  const proxyUrl = `${Config.PUBLIC_URL}/api/v1/setu/consents`;
  const res = await fetch(proxyUrl, {
    method: 'POST',
    body: JSON.stringify({
      mobileNumber: '9967819918',
      redirectUri: `${Config.PUBLIC_URL}${Routes.SetuCallback}`,
      fipId: fipId,
    }),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });
  if (!res.ok) {
    console.error(await res.text());
    throw new Error('Error getting authorization token ');
  }
  const resp: ConsentResponseV2 = await res.json();
  return resp.url;
}

export async function fetchSessionWithConsent(
  consentId: string
): Promise<FIDataFetchResponseV2> {
  const proxyUrl = `${Config.PUBLIC_URL}/api/v1/setu/sessions`;
  const res = await fetch(proxyUrl, {
    method: 'POST',
    body: JSON.stringify({
      consentId: consentId,
    }),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });
  if (!res.ok) {
    console.error(await res.text());
    throw new Error('Error getting authorization token ');
  }
  const resp: FIDataFetchResponseV2 = await res.json();
  return resp;
}

async function getFhirResource<T extends MergedFhirResource>(
  connectionDocument: ConnectionDocument,
  _fhirResourcePathUrl: string
): Promise<BundleEntry<T>[]> {
  const sessionId = connectionDocument.access_token;
  const proxyUrl = `${Config.PUBLIC_URL}/api/v1/setu/sessions/${sessionId}`;
  const res = await fetch(proxyUrl, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })
    .then((res) => {
      return res.json();
      // return fiuJson as never as FIDataFetchResponseV2;
    })
    .then((json: FIDataFetchResponseV2) => {
      return mapDataToBundle(json);
    });

  if (res.entry) {
    return res.entry as BundleEntry<T>[];
  }
  return [];
}

async function syncFhirResource<T extends MergedFhirResource>(
  connectionDocument: ConnectionDocument,
  db: Firestore,
  fhirResourceUrl: string,
  mapper: (proc: BundleEntry<T>) => ClinicalDocument<BundleEntry<T>>
) {
  const fhirResources = await getFhirResource<T>(
    connectionDocument,
    fhirResourceUrl
  );
  const cds = fhirResources
    .filter(
      (i) =>
        i.resource?.resourceType.toLowerCase() === fhirResourceUrl.toLowerCase()
    )
    .map(mapper);
  const batch = writeBatch(db);
  cds.map((cd) => {
    batch.set(doc(collections.clinicalDocuments(db), cd.id), cd);
  });
  const cdsmap = await batch.commit();
  // const cdsmap = await db.clinical_documents.bulkUpsert(
  //   cds as unknown as ClinicalDocument[]
  // );
  return cdsmap;
}

export async function syncAllRecords(
  connectionDocument: ConnectionDocument,
  db: Firestore
): Promise<PromiseSettledResult<void[]>[] | any> {
  const newCd = connectionDocument;
  newCd.last_refreshed = new Date().toISOString();

  const transactionMapper = (dr: BundleEntry<Transaction>) =>
    mapTransactionToClinicalDocument(dr, connectionDocument);

  const syncJob = await Promise.allSettled([
    syncFhirResource<Transaction>(
      connectionDocument,
      db,
      'Transaction',
      transactionMapper
    ),
  ]);

  // await db.connection_documents.upsert(newCd).then(() => []);
  await updateDoc(doc(collections.connectionDocuments(db), newCd.id), newCd);

  return syncJob;
}
