import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ulid } from 'ulid';
import { UserPreferencesDocument } from '../../models/user-preferences/UserPreferences.type';
import { useUser } from './UserProvider';
import {
  DocumentSnapshot,
  Firestore,
  Unsubscribe,
  doc,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  where,
} from 'firebase/firestore';
import { collections, useFirestoreDb } from './FirebaseProvider';

// Takes a user id and returns a default user preferences document
function createDefaultPreferences(
  user_id: string
): Partial<UserPreferencesDocument> {
  return { use_proxy: true, user_id, id: ulid(), uid: user_id };
}

/**
 * Take the whole RxDocument and return a parsed version with defaults
 * @param item
 * @returns
 */
export function getUserPreferencesFromFireSnap(
  item: DocumentSnapshot<UserPreferencesDocument>,
  user_id: string
) {
  return {
    ...createDefaultPreferences(user_id),
    ...item?.data(),
  } as UserPreferencesDocument;
}

/**
 * Creates a user preferences document if none exists
 * @param db
 * @param user_id
 * @returns A promise that resolves to true if a new document was created, false if not
 */
function createUserPreferencesIfNone(
  db: Firestore,
  user_id: string
): Promise<boolean> {
  return new Promise((resolve, reject) => {
    const q = query(
      collections.userPreferences(db),
      where('user_id', '==', user_id)
    );
    getDocs(q).then((docSnap) => {
      if (!docSnap.empty) {
        resolve(false);
      } else {
        const docRef = doc(collections.userPreferences(db), ulid());
        setDoc(docRef, createDefaultPreferences(user_id))
          .then(() => resolve(true))
          .catch(() => reject(false));
      }
    });
  });
}

/**
 * Fetches the user preferences document and calls a callback whenever the document is updated. Returns a Subscription object that can be used to unsubscribe
 * @param db
 * @param user
 * @param handleChange Callback that takes the updated user preferences and updates the state
 * @returns Subscription object that can be used to unsubscribe
 */
function fetchUserPreferences(
  db: Firestore,
  user_id: string,
  handleChange: (item: UserPreferencesContextType | undefined) => void
): Unsubscribe {
  const q = query(
    collections.userPreferences(db),
    where('user_id', '==', user_id)
  );
  const unsub = onSnapshot(q, (items) => {
    if (items.empty) {
      return;
    }
    const item = items.docs[0];
    console.log(item?.data());
    handleChange({
      userPreferences: getUserPreferencesFromFireSnap(item, user_id),
      rawUserPreferences: item,
    });
  });
  return unsub;
}

type UserPreferencesProviderProps = PropsWithChildren<unknown>;

type UserPreferencesContextType = {
  userPreferences?: UserPreferencesDocument;
  rawUserPreferences?: DocumentSnapshot<UserPreferencesDocument>;
};

const UserPreferencesContext = React.createContext<
  UserPreferencesDocument | undefined
>(undefined);

const RawUserPreferencesContext = React.createContext<
  DocumentSnapshot<UserPreferencesDocument> | undefined
>(undefined);

/**
 * To all descendents, expose the parsed user preferences (with defaults) and the the raw RxDocument for update ability
 * @param props
 * @returns
 */
export function UserPreferencesProvider(props: UserPreferencesProviderProps) {
  const user = useUser(),
    [upContext, setUpContext] = useState<UserPreferencesDocument | undefined>(
      undefined
    ),
    [rupContext, setRupContext] = useState<
      DocumentSnapshot<UserPreferencesDocument> | undefined
    >(),
    hasRun = useRef(false);
  const firestoreDb = useFirestoreDb();

  useEffect(() => {
    let sub: Unsubscribe | undefined;

    if (!hasRun.current) {
      hasRun.current = true;
      createUserPreferencesIfNone(firestoreDb, user.id).then(() => {
        sub = fetchUserPreferences(firestoreDb, user.id, (item) => {
          setUpContext(item?.userPreferences);
          setRupContext(item?.rawUserPreferences);
        });
      });
    }
    return () => {
      if (sub) {
        sub();
      }
    };
  }, [firestoreDb, user.id]);

  return (
    <UserPreferencesContext.Provider value={upContext}>
      <RawUserPreferencesContext.Provider value={rupContext}>
        {props.children}
      </RawUserPreferencesContext.Provider>
    </UserPreferencesContext.Provider>
  );
}

/**
 * A react hook to get the parsed user preferences (with defaults). Not modifiable
 * @returns The parsed user preferences (with defaults)
 */
export function useUserPreferences(): UserPreferencesDocument | undefined {
  const context = useContext(UserPreferencesContext);
  return context;
}

/**
 * A react hook to get the raw RxDocument for the user preferences. Use this to update the document
 * @returns The raw RxDocument for the user preferences. Use this to update the document
 */
export function useRawUserPreferences():
  | DocumentSnapshot<UserPreferencesDocument>
  | undefined {
  const context = useContext(RawUserPreferencesContext);
  return context;
}
