import { Route, RouteProps } from 'react-router-dom';
import { useUser } from './providers/UserProvider';
import { ReactElement } from 'react';

export const ProtectedRoute = ({ children }: RouteProps): ReactElement => {
  const user = useUser();

  console.log('protected route', user);
  // if (!user) {
  //   return <Navigate to="/login" />;
  // }

  if (user) {
    return <>{children}</>;
  }
  return <></>;
};
