import { format, parseISO } from 'date-fns';
import { BundleEntry, MergedFhirResource } from 'fhir/r2';
import {
  ReactGrid,
  Column,
  Row,
  CellChange,
  Id,
  MenuOption,
} from '@silevis/reactgrid';
import { ClinicalDocument } from '../../models/clinical-document/ClinicalDocument.type';
import { Transaction } from '@mere/setu';
import '@silevis/reactgrid/styles.css';
import { useState } from 'react';
import { TagsModal } from './TagsModal';
import { useAllClinicalTags } from '../providers/TagsProvider';
import { classNames } from '../../utils/StyleUtils';

const calculateNumberOfRows = (text: string): number => {
  const charactersPerLine = 25;
  const lineHeight = 25;
  const minHeight = 50;

  const numberOfLines = Math.ceil(text.length / charactersPerLine);
  const height = numberOfLines * lineHeight;

  return Math.max(Math.ceil(height), minHeight);
};

const getColumns = (
  _itemList: ClinicalDocument<BundleEntry<Transaction>>[]
): Column[] => [
  { columnId: 'date', width: 100 },
  { columnId: 'displayName', width: 250 },
  { columnId: 'credit', width: 100 },
  { columnId: 'debit', width: 100 },
  // { columnId: 'tags', width: 100 },
];

const getHeaderRow = (
  _itemList: ClinicalDocument<BundleEntry<Transaction>>[]
): Row => {
  return {
    rowId: 'header',
    cells: [
      { type: 'header', text: 'Date' },
      { type: 'header', text: 'Name' },
      { type: 'header', text: 'Credit' },
      { type: 'header', text: 'Debit' },
      // { type: 'header', text: 'Tags' },
    ],
    height: 50,
  };
};

export function TimelineTable({
  dateKey,
  itemList,
}: {
  dateKey: string;
  itemList: ClinicalDocument<BundleEntry<MergedFhirResource>>[];
}) {
  const [selectedItem, setSelectedItem] =
    useState<ClinicalDocument<BundleEntry<MergedFhirResource>>>();
  const [isTagsModalOpen, setTagsModalOpen] = useState(false);
  const allClTags = useAllClinicalTags();

  const getRows = (
    itemList: ClinicalDocument<BundleEntry<Transaction>>[]
  ): Row[] => [
    getHeaderRow(itemList),
    ...itemList
      .filter((item) => item.data_record.raw.resource)
      .map<Row>((item, idx) => {
        const clTags = allClTags.filter(
          (clTag) => clTag.clinical_document_id === item.id
        );

        const displayName = clTags.length
          ? [...clTags.map((clTag) => clTag.name)].join(' / ')
          : item.metadata?.display_name;

        const dateFormat = new Intl.DateTimeFormat('en-US', {
          month: 'short',
          day: 'numeric',
          year: '2-digit',
          hour: 'numeric',
          minute: 'numeric',
          formatMatcher: 'basic',
        });
        const transaction = item.data_record.raw.resource as Transaction;
        const cells: Row['cells'] = [
          {
            type: 'time',
            time: new Date(item.metadata?.date || ''),
            format: dateFormat,
            style: { overflow: 'hidden' },
            className: 'bg-slate-100 !whitespace-pre-line',
            nonEditable: true,
          },
          {
            type: 'text',
            text: displayName || '',

            className: classNames(
              clTags.length > 0 ? 'font-bold tracking-tight' : '',
              '!text-balance !whitespace-pre-wrap'
            ),
            nonEditable: true,
          },
        ];

        if (transaction.type.toLowerCase() === 'credit') {
          cells.push({
            type: 'number',
            value: +(transaction.amount || ''),
            className: '!text-green-500 font-semibold',
            nonEditable: true,
          });
          cells.push({ type: 'text', text: '', nonEditable: true });
        } else if (transaction.type.toLowerCase() === 'debit') {
          cells.push({ type: 'text', text: '', nonEditable: true });
          cells.push({
            type: 'number',
            value: +(transaction.amount || ''),
            className: '!text-red-500 font-semibold',
            nonEditable: true,
          });
        }
        return {
          rowId: idx,
          cells: cells,
          height: calculateNumberOfRows(displayName || ''),
        };
      }),
  ];

  const rows = getRows(itemList as any);

  const columns = getColumns(itemList as any);

  const simpleHandleContextMenu = (
    selectedRowIds: Id[],
    selectedColIds: Id[],
    selectionMode: any,
    menuOptions: MenuOption[]
  ): MenuOption[] => {
    console.log('selectionMode', selectionMode, selectedRowIds, selectedColIds);
    const newMenuOptions: MenuOption[] = [];
    if (selectionMode === 'row') {
      newMenuOptions.push({
        id: 'manage-tags',
        label: 'Manage Tags',
        handler: (rowIds) => {
          const rowId = rowIds[0];
          console.log('inside handler', rowIds);
          setSelectedItem(itemList[+rowId]);
          setTagsModalOpen(true);
        },
      });
    }
    newMenuOptions.push(...menuOptions);
    return newMenuOptions;
  };

  const onCellsChanged = (changes: CellChange[]) => {
    console.log('came here', changes);
    changes.forEach((change) => {
      const column = columns.find((col) => col.columnId === change.columnId);
      const itemIdx = itemList.findIndex((log) => log.id === change.rowId);
      if (itemIdx === -1 || !column) return;
      console.log('change', change, itemIdx, column);
    });
  };

  return (
    <div
      className="flex flex-1 scroll-mt-10 flex-col gap-x-4 overflow-scroll px-0 pt-4 sm:pt-8"
      key={dateKey}
    >
      <div className="w-5/5 flex flex-col md:w-3/4">
        <ReactGrid
          onCellsChanged={onCellsChanged}
          onContextMenu={simpleHandleContextMenu}
          rows={rows}
          columns={columns}
          stickyRightColumns={2}
          enableRowSelection
        />
      </div>
      <TagsModal
        open={isTagsModalOpen}
        setOpen={setTagsModalOpen}
        doc={selectedItem as any}
      />
    </div>
  );
}
