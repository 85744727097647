import React from 'react';
import logoCol from '../../assets/logo.svg';
import logo from '../../img/white-logo.svg';
import { Transition } from '@headlessui/react';
import { AppPage } from '../AppPage';
import { TimelineBanner } from '../timeline/TimelineBanner';
import { SearchBar } from '../../pages/SearchBar';
import { QueryStatus } from '../../pages/TimelineTab';

export function AppLoadingSkeleton({ ready }: { ready?: boolean }) {
  return (
    <div className="mobile-full-height flex max-w-[100vw] overflow-hidden md:flex-row-reverse">
      <div className="flex-grow">
        <AppPage banner={<TimelineBanner />}>
          <Transition
            as="div"
            className={
              'flex h-full w-full flex-col items-center justify-center'
            }
            show={ready}
            leave="transition-opacity ease-in-out duration-75"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            enter="transition-opacity ease-in-out duration-75"
            enterFrom="opacity-0"
            enterTo="opacity-100"
          >
            <div className="relative h-24 w-24 md:h-48 md:w-48">
              <img
                className="absolute top-0 left-0 h-24 w-24 animate-ping opacity-25 md:h-48 md:w-48"
                src={logoCol}
                alt="Loading screen"
              ></img>
              <img
                className="absolute top-0 left-0 h-24 w-24 opacity-25 md:h-48 md:w-48"
                src={logoCol}
                alt="Loading screen"
              ></img>
            </div>
          </Transition>
        </AppPage>
      </div>
      (
      <div
        className="grid h-full overflow-y-auto border-gray-200"
        style={{
          gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
          gridTemplateRows: 'repeat(auto-fit, 300px)',
        }}
      >
        <div
          style={{
            gridRow: 1,
            gridColumn: '1',
            backgroundColor: 'white',
            position: 'sticky',
            top: 0,
          }}
        >
          <img src="/assets/apvotes.png" alt="" />
          <div className="m-4">
            <SearchBar
              query={''}
              setQuery={() => {}}
              status={QueryStatus.IDLE}
            ></SearchBar>
          </div>
        </div>
      </div>
      );
    </div>
  );
}
