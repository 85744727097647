import { MigrationStrategies } from 'rxdb';

export const ClinicalTagMigrations: MigrationStrategies = {
  1: function (oldDoc) {
    return oldDoc;
  },
  2: function (oldDoc) {
    return oldDoc;
  },
};
