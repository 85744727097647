export enum Routes {
  Login = '/login',
  Dasboard = '/dashboard',
  Timeline = '/timeline',
  AddConnection = '/connections',
  Summary = '/summary',
  Assist = '/assist',
  Settings = '/settings',
  OnPatientCallback = '/onpatient/callback',
  EpicCallback = '/epic/callback',
  CernerCallback = '/cerner/callback',
  VeradigmCallback = '/veradigm/callback',
  SetuCallback = '/setu/callback ',
  Vote = '/vote',
}
