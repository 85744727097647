import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { FirebaseApp, initializeApp, getApps } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';
import { Transition } from '@headlessui/react';
import Config from '../../environments/config.json';
import { AppLoadingSkeleton } from './AppLoadingSkeleton';
import {
  CollectionReference,
  Firestore,
  collection,
  getFirestore,
} from 'firebase/firestore';
import { UserPreferencesDocument } from '../../models/user-preferences/UserPreferences.type';
import { ConnectionDocument } from '../../models/connection-document/ConnectionDocument.type';
import { UserTagDocument } from '../../models/user-tag/UserTag.type';
import { ClinicalTagDocument } from '../../models/clinical-tag/ClinicalTag.type';
import { ClinicalDocument } from '../../models/clinical-document/ClinicalDocument.type';
import { getAuth } from 'firebase/auth';
import { VoteDocument } from '../../models/vote-document/VoteDocument.type';

export const firebaseConfig = {
  apiKey: Config.FIREBASE_API_KEY,
  authDomain: Config.FIREBASE_AUTH_DOMAIN,
  projectId: Config.FIREBASE_PROJECT_ID,
  storageBucket: Config.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: Config.FIREBASE_MESSAGING_SENDER_ID,
  appId: Config.FIREBASE_APP_ID,
  measurementId: Config.FIREBASE_MEASUREMENT_ID,
};

type FirebaseProviderProps = PropsWithChildren<unknown>;

// Create a Firebase context
export const FirebaseContext = createContext<FirebaseApp | undefined>(
  undefined
);

// Firebase provider component
export const FirebaseProvider = (props: FirebaseProviderProps) => {
  const [firebaseInstance, setFirebaseInstance] = useState<FirebaseApp>();

  useEffect(() => {
    // Check if Firebase instance exists
    if (!getApps().length) {
      // Initialize Firebase
      const app = initializeApp(firebaseConfig);
      const analytics = getAnalytics(app);
      console.log('setting firebase', app);
      setFirebaseInstance(app);
    }
  }, []);

  return (
    <>
      {/* Transition added to avoid flash of white  */}
      {/* <Transition
        show={!firebaseInstance}
        appear={true}
        enter="transition-opacity duration-150"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-linear duration-75"
        leaveFrom="opacity-100"
        leaveTo="opacity-[.99]"
      >
        <AppLoadingSkeleton ready={!firebaseInstance} />
      </Transition> */}
      {firebaseInstance && (
        <FirebaseContext.Provider value={firebaseInstance}>
          {props.children}
        </FirebaseContext.Provider>
      )}
    </>
  );
};

export function useFirebase() {
  const context = useContext(FirebaseContext);
  if (context === undefined) {
    throw new Error('useFirebase must be used within a FirebaseProvider');
  }
  return context;
}

export enum Collection {
  USER_PREFERENCES = 'user_preferences',
  CONNECTION_DOCUMENTS = 'connection_documents',
  USER_TAGS = 'user_tags',
  CLINICAL_TAGS = 'clinical_tags',
  CLINICAL_DOCUMENTS = 'clinical_documents',
  VOTES = 'votes',
}

export const collections = {
  votes: (db: Firestore): CollectionReference<VoteDocument> =>
    collection(
      db,
      Collection.VOTES
    ) as never as CollectionReference<VoteDocument>,
  userPreferences: (
    db: Firestore
  ): CollectionReference<UserPreferencesDocument> =>
    collection(
      db,
      Collection.USER_PREFERENCES
    ) as never as CollectionReference<UserPreferencesDocument>,
  connectionDocuments: (
    db: Firestore
  ): CollectionReference<ConnectionDocument> =>
    collection(
      db,
      Collection.CONNECTION_DOCUMENTS
    ) as never as CollectionReference<ConnectionDocument>,
  userTags: (db: Firestore): CollectionReference<UserTagDocument> =>
    collection(
      db,
      Collection.USER_TAGS
    ) as never as CollectionReference<UserTagDocument>,
  clinicalTags: (db: Firestore): CollectionReference<ClinicalTagDocument> =>
    collection(
      db,
      Collection.CLINICAL_TAGS
    ) as never as CollectionReference<ClinicalTagDocument>,
  clinicalDocuments: (db: Firestore): CollectionReference<ClinicalDocument> =>
    collection(
      db,
      Collection.CLINICAL_DOCUMENTS
    ) as never as CollectionReference<ClinicalDocument>,
};

export function useFirestoreDb() {
  const firebaseApp = useFirebase();
  const db = getFirestore(firebaseApp);
  return db;
}

export function useFireAuth() {
  const firebase = useFirebase();
  const auth = getAuth(firebase);
  return auth;
}
