import { Transaction } from '@mere/setu';
import { Bundle, BundleEntry } from 'fhir/r2';
import { ConnectionDocument } from '../models/connection-document/ConnectionDocument.type';
import { ClinicalDocument } from '../models/clinical-document/ClinicalDocument.type';
import { ulid } from 'ulid';

export function mapDataToBundle(data: any): Bundle<Transaction> {
  const bundle: Bundle<Transaction> = {
    resourceType: 'Bundle',
    type: 'collection',
  } as Bundle<Transaction>;

  const transactions: Transaction[] = data.fips.flatMap((fip: any) => {
    return fip.accounts.flatMap((account: any) => {
      return account.data.account.transactions.transaction.map(
        (transactionData: any) => {
          const transaction: Transaction = {
            amount: transactionData.amount,
            currentBalance: transactionData.currentBalance,
            mode: transactionData.mode,
            narration: transactionData.narration,
            reference: transactionData.reference,
            transactionTimestamp: transactionData.transactionTimestamp,
            txnId: transactionData.txnId,
            type: transactionData.type,
            valueDate: transactionData.valueDate,
            profile: account.data.account.profile,
            summary: account.data.account.summary,
            resourceType: 'Transaction',
          };
          return transaction;
        }
      );
    });
  });

  const bundleEntries: BundleEntry<Transaction>[] = transactions.map(
    (transaction: Transaction) => {
      return {
        resource: transaction,
        fullUrl: `/api/setu/transactions/${transaction.txnId}`,
      };
    }
  );

  bundle.entry = bundleEntries;

  return bundle;
}

export function mapTransactionToClinicalDocument(
  bundleItem: BundleEntry<Transaction>,
  connectionDocument: ConnectionDocument
) {
  const cd: ClinicalDocument<BundleEntry<Transaction>> = {
    //id: uuid4(),
    id: ulid(),
    user_id: connectionDocument.user_id,
    connection_record_id: connectionDocument.id,
    data_record: {
      raw: bundleItem,
      format: 'FHIR.DSTU2',
      content_type: 'application/json',
      resource_type: 'transaction',
      version_history: [],
    },
    metadata: {
      id: bundleItem.resource?.txnId,
      date: bundleItem.resource?.valueDate || new Date(0).toISOString(),
      display_name: bundleItem.resource?.narration,
    },
  };
  return cd;
}
