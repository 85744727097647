import React, { useRef, useContext } from 'react';
import { PropsWithChildren, useEffect, useState } from 'react';
import { Subscription } from 'rxjs';
import { UserDocument } from '../../models/user-document/UserDocument.type';
import { UserTagDocument } from '../../models/user-tag/UserTag.type';
import { useUser } from './UserProvider';
import { ClinicalDocument } from '../../models/clinical-document/ClinicalDocument.type';
import { ClinicalTagDocument } from '../../models/clinical-tag/ClinicalTag.type';
import { collections, useFirestoreDb } from './FirebaseProvider';
import {
  Firestore,
  Unsubscribe,
  doc,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  where,
} from 'firebase/firestore';
import { ulid } from 'ulid';

function fetchUserTags(
  db: Firestore,
  user: UserDocument,
  handleChange: (item: { tags: UserTagDocument[] } | undefined) => void
) {
  const q = query(collections.userTags(db), where('user_id', '==', user.id));
  const unsub = onSnapshot(q, async (items) => {
    console.log('items', items);
    handleChange({
      tags: items.docs.map((item) => item.data() as UserTagDocument),
    });
  });
  return unsub;
}

function fetchClinicalTags(
  db: Firestore,
  user: UserDocument,
  handleChange: (item: { tags: ClinicalTagDocument[] } | undefined) => void
) {
  const q = query(
    collections.clinicalTags(db),
    where('user_id', '==', user.id)
  );
  const unsub = onSnapshot(q, async (items) => {
    console.log('items', items);
    handleChange({
      tags: items.docs.map((item) => item.data() as ClinicalTagDocument),
    });
  });
  return unsub;
}

type UserTagsProviderProps = PropsWithChildren<unknown>;
type ClinicalTagsProviderProps = PropsWithChildren<unknown>;

const UserTagsContext = React.createContext<UserTagDocument[]>([]);
const ClinicalTagsContext = React.createContext<ClinicalTagDocument[]>([]);

const defaultTags: Pick<UserTagDocument, 'name'>[] = [
  {
    name: 'Bills',
  },
  {
    name: 'Groceries',
  },
];

function createUserTagsIfNone(
  db: Firestore,
  user_id: string
): Promise<boolean> {
  return new Promise((resolve, reject) => {
    const q = query(collections.userTags(db), where('user_id', '==', user_id));
    getDocs(q).then((docSnap) => {
      if (!docSnap.empty) {
        resolve(false);
      } else {
        const promises: any[] = [];
        defaultTags.forEach((tag) => {
          const id = ulid();
          const docRef = doc(collections.userTags(db), id);
          promises.push(
            setDoc(docRef, { ...tag, user_id: user_id, id, uid: user_id })
          );
        });
        Promise.all(promises)
          .then(() => resolve(true))
          .catch(() => reject(false));
      }
    });
  });
}

export function UserTagsProvider(props: UserTagsProviderProps) {
  const db = useFirestoreDb();
  const user = useUser();
  const [tags, setTags] = useState<UserTagDocument[]>([]);
  const hasRun = useRef(false);

  useEffect(() => {
    let sub: Unsubscribe | undefined;
    if (!hasRun.current) {
      hasRun.current = true;
      createUserTagsIfNone(db, user.id).then(() => {
        sub = fetchUserTags(db, user, (resp) => {
          if (resp && resp.tags) {
            setTags(resp.tags);
          }
        });
      });
    }
    return () => {
      if (sub) {
        sub();
      }
    };
  }, [db]);

  if (user) {
    return (
      <UserTagsContext.Provider value={tags}>
        {props.children}
      </UserTagsContext.Provider>
    );
  }

  return null;
}

export function ClinicalTagsProvider(props: ClinicalTagsProviderProps) {
  const db = useFirestoreDb();
  const user = useUser();
  const [clinicalTags, setClinicalTags] = useState<ClinicalTagDocument[]>([]);
  const hasRun = useRef(false);

  useEffect(() => {
    let sub: Unsubscribe | undefined;
    if (!hasRun.current) {
      hasRun.current = true;
      sub = fetchClinicalTags(db, user, (resp) => {
        if (resp && resp.tags) {
          console.log('updated tags', resp.tags);
          setClinicalTags(resp.tags);
        }
      });
    }
    return () => {
      if (sub) {
        sub();
      }
    };
  }, [db]);

  if (user) {
    return (
      <ClinicalTagsContext.Provider value={clinicalTags}>
        {props.children}
      </ClinicalTagsContext.Provider>
    );
  }

  return null;
}

/**
 * Gets a parsed user document from the context, not modifiable
 * @returns
 */
export function useUserTags() {
  const context = useContext(UserTagsContext);
  return context;
}

export function useClinicalTags(doc: ClinicalDocument) {
  const context = useContext(ClinicalTagsContext);
  if (!doc) {
    return [];
  }
  const clTags = context.filter((clTag) => {
    return clTag.clinical_document_id === doc.id;
  });
  console.log('filtered cl tags for doc', doc.id, clTags);
  return clTags;
}

export function useAllClinicalTags() {
  const context = useContext(ClinicalTagsContext);
  const userTags = useUserTags();
  return context.map((clTag) => {
    const userTag = userTags.find((userTag) => {
      return userTag.id === clTag.user_tag_id;
    });
    return {
      ...userTag,
      ...clTag,
    };
  });
}
