export const userTagSchemaLiteral = {
  title: 'User Tag Schema',
  version: 1,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      maxLength: 128,
    },
    user_id: {
      ref: 'user_documents',
      type: 'string',
      maxLength: 128,
    },
    name: {
      type: 'string',
    },
  },
  indexes: ['user_id'],
} as const;
