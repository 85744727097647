export const clinicalTagSchemaLiteral = {
  title: 'Clinical Tag Schema',
  version: 2,
  primaryKey: {
    key: 'id',
    fields: ['clinical_document_id', 'user_tag_id'] as string[],
    // separator which is used to concat the fields values.
    separator: '|',
  },
  type: 'object',
  properties: {
    id: {
      type: 'string',
      maxLength: 128,
    },
    user_id: {
      ref: 'user_documents',
      type: 'string',
      maxLength: 128,
    },
    user_tag_id: {
      ref: 'user_tags',
      type: 'string',
      maxLength: 128,
    },
    clinical_document_id: {
      ref: 'clinical_documents',
      type: 'string',
      maxLength: 128,
    },
  },
  indexes: ['clinical_document_id'],
} as const;
