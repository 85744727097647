import { memo, useCallback, useState } from 'react';
import { Combobox } from '@headlessui/react';
import {
  CheckIcon,
  MagnifyingGlassIcon,
  PlusIcon,
} from '@heroicons/react/20/solid';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { useDebounce } from '@react-hook/debounce';
import { Modal } from '../Modal';
import { ModalHeader } from '../ModalHeader';

import { classNames } from '../../utils/StyleUtils';
import { ClinicalDocument } from '../../models/clinical-document/ClinicalDocument.type';
import { BundleEntry } from 'fhir/r2';
import { Transaction } from '@mere/setu';
import { useClinicalTags, useUserTags } from '../providers/TagsProvider';
import { UserTagDocument } from '../../models/user-tag/UserTag.type';
import { useUser } from '../providers/UserProvider';
import { ClinicalTagDocument } from '../../models/clinical-tag/ClinicalTag.type';
import { useNotificationDispatch } from '../providers/NotificationProvider';
import { LoadingSpinner } from '../LoadingSpinner';
import { collections, useFirestoreDb } from '../providers/FirebaseProvider';
import { writeBatch, doc as fireDoc, setDoc } from 'firebase/firestore';
import { ulid } from 'ulid';

function wait(delayInMS: number) {
  return new Promise((resolve) => setTimeout(resolve, delayInMS));
}

function getNewAndDeletedIds(clTags: ClinicalTagDocument[], array2: string[]) {
  const newArray = [...array2];
  const deletedClTagIds: string[] = [];

  // Find new IDs in array2
  const newUserTagIds = newArray.filter(
    (id) => !clTags.find((clTag) => clTag.user_tag_id === id)
  );

  // Find deleted IDs from array1
  clTags.forEach((clTag) => {
    if (!newArray.includes(clTag.user_tag_id)) {
      deletedClTagIds.push(clTag.id);
    }
  });

  return [newUserTagIds, deletedClTagIds];
}

function getCreateNewUserTagAndRest(array2: string[]) {
  const newUserTagIds = array2.filter((id) => id.startsWith('Create '));
  const rest = array2.filter((id) => !id.startsWith('Create '));

  return [newUserTagIds, rest];
}

export function TagItem({
  id,
  userTag,
  isLoading,
}: {
  id: string;
  userTag: UserTagDocument;
  isLoading: boolean;
}) {
  const createNew = id === 'create';
  return (
    <Combobox.Option
      tabIndex={0}
      key={id}
      disabled={isLoading}
      value={userTag.id}
      className={({ active, selected }) =>
        classNames(
          active ? 'bg-gray-100' : '',
          'mb-2 flex cursor-default select-none rounded-xl p-3'
        )
      }
    >
      {({ active, selected }) => (
        <div className="flex-auto">
          <div className="relative px-4">
            {!isLoading && createNew && (
              <PlusIcon className="pointer-events-none absolute h-5 w-5 text-gray-400"></PlusIcon>
            )}
            {!isLoading && selected && !createNew && (
              <CheckIcon className="pointer-events-none absolute h-5 w-5 text-gray-400"></CheckIcon>
            )}
            {/* {!isLoading && !selected && !createNew && (
              <XMarkIcon className="pointer-events-none absolute h-5 w-5 text-gray-400"></XMarkIcon>
            )} */}
            {isLoading && (
              <div className="pointer-events-none absolute h-5 w-5 text-gray-400">
                <LoadingSpinner />
              </div>
            )}
            <p
              className={classNames(
                'focus:ring-primary-700 w-full divide-y-2 rounded-xl border-0 bg-gray-50 bg-transparent pl-8 pr-4 text-gray-800 placeholder-gray-400 hover:border-gray-200 focus:ring-2 sm:text-sm',
                active ? 'text-gray-900' : 'text-gray-700'
              )}
            >
              {userTag.name}
            </p>
          </div>
        </div>
      )}
    </Combobox.Option>
  );
}

export function TagsModal({
  open,
  setOpen,
  doc,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  doc: ClinicalDocument<BundleEntry<Transaction>>;
}) {
  const userTags = useUserTags();
  const db = useFirestoreDb();
  const user = useUser();
  const clTags = useClinicalTags(doc);
  const [query, setQuery] = useDebounce('', 150);
  const notifyDispatch = useNotificationDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const filteredUserTags =
    query === ''
      ? userTags
      : userTags.filter((userTag) => {
          return userTag.name.toLowerCase().includes(query.toLowerCase());
        });

  const showCreate =
    query.length >= 3 &&
    filteredUserTags.every(
      (userTag) => userTag.name.toLowerCase() != query.toLowerCase()
    );

  const updateTags = useCallback(
    async (allTags: string[]) => {
      setIsLoading(true);
      console.log('chnge', allTags);
      // return;
      await wait(500);

      const [createNewUserTag, tags] = getCreateNewUserTagAndRest(allTags);
      const newIds: string[] = [];
      if (createNewUserTag.length) {
        for (const id of createNewUserTag) {
          const uqid = ulid();
          const res = await setDoc(fireDoc(collections.userTags(db), uqid), {
            id: uqid,
            uid: user.id,
            name: id.split('Create ')[1],
            user_id: user.id,
          });
          newIds.push(uqid);
        }
        setQuery('');
        tags.push(...newIds.map((id) => id || ''));
      }

      const [newUserTagIds, deletedClTagIds] = getNewAndDeletedIds(
        [...clTags],
        tags
      );
      const batch = writeBatch(db);
      if (deletedClTagIds.length) {
        deletedClTagIds.forEach((id) => {
          batch.delete(fireDoc(collections.clinicalTags(db), id));
        });
      }
      if (newUserTagIds.length) {
        newUserTagIds.forEach((id) => {
          const uniqid = ulid();
          batch.set(fireDoc(collections.clinicalTags(db), uniqid), {
            id: uniqid,
            user_tag_id: id,
            clinical_document_id: doc.id || '',
            user_id: user.id,
            uid: user.id,
          });
        });
      }
      const res = await batch.commit();
      notifyDispatch({
        type: 'set_notification',
        message: 'Tags updated successfully',
        variant: 'success',
      });
      setIsLoading(false);
    },
    [clTags, db, doc, notifyDispatch, setQuery, user, userTags]
  );

  console.log('usertags', userTags);
  console.log('clTags', doc?.id, clTags);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      afterLeave={() => setQuery('')}
      overflowHidden
    >
      {doc && (
        <>
          <ModalHeader
            title={doc.metadata?.display_name || ''}
            setClose={() => setOpen((x) => !x)}
          />
          <Combobox
            value={clTags.map((clTag) => clTag.user_tag_id)}
            onChange={updateTags}
            multiple
          >
            <>
              {
                <>
                  <div className="relative px-4">
                    <MagnifyingGlassIcon
                      className="pointer-events-none absolute top-3.5 left-8 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <Combobox.Input
                      className="focus:ring-primary-700 h-12 w-full divide-y-2 rounded-xl border-0 bg-gray-50 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 hover:border-gray-200 focus:ring-2 sm:text-sm"
                      placeholder="Search or add tags"
                      onChange={(event) => setQuery(event.target.value)}
                      autoFocus={true}
                    />
                  </div>

                  {userTags.length > 0 && (
                    <Combobox.Options
                      static
                      hold={true}
                      className={classNames(
                        ' h-36 max-h-full scroll-py-3 overflow-y-scroll p-3 sm:max-h-96 ',
                        filteredUserTags.length > 0 ? 'mb-20' : ''
                      )}
                    >
                      {showCreate && (
                        <MemoizedTagItem
                          id={'create'}
                          userTag={{
                            name: `Create "${query}"`,
                            id: `Create ${query}`,
                            user_id: user.id,
                            uid: user.id,
                          }}
                          isLoading={isLoading}
                        />
                      )}
                      {filteredUserTags.map((tag) => (
                        <MemoizedTagItem
                          id={tag.id}
                          userTag={tag}
                          key={tag.id}
                          isLoading={isLoading}
                        />
                      ))}
                    </Combobox.Options>
                  )}

                  {query !== '' && filteredUserTags.length === 0 && (
                    <div className="py-6 px-6 text-center text-sm sm:px-14">
                      <ExclamationCircleIcon
                        type="outline"
                        name="exclamation-circle"
                        className="mx-auto h-6 w-6 text-gray-400"
                      />
                      <p className="mt-4 font-semibold text-gray-900">
                        No results found
                      </p>
                      <p className="mt-2 text-gray-500">
                        No Tags found for this search term. Try creating a new.
                      </p>
                    </div>
                  )}
                </>
              }
            </>
          </Combobox>
        </>
      )}
    </Modal>
  );
}

export const MemoizedTagItem = memo(TagItem);
