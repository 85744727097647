import { Routes, Route, Navigate, Link } from 'react-router-dom';
import { Routes as AppRoutes } from '../Routes';
import VoteTab from '../pages/VoteTab';

export function TabWrapper() {
  return (
    <div className="mobile-full-height flex max-w-[100vw] overflow-hidden md:flex-row-reverse">
      <div className="w-full">
        <Routes>
          <Route path={AppRoutes.Vote} element={<VoteTab />} />

          <Route path="*" element={<Navigate to={AppRoutes.Vote} />} />
        </Routes>
      </div>
    </div>
  );
}
