import { RxCollection } from 'rxdb';
import {
  toTypedRxJsonSchema,
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxJsonSchema,
} from 'rxdb';
import { userTagSchemaLiteral } from './UserTag.schema';

export const userTagSchemaTyped = toTypedRxJsonSchema(userTagSchemaLiteral);

type UserTagDocumentType = ExtractDocumentTypeFromTypedRxJsonSchema<
  typeof userTagSchemaTyped
>;

export const UserTagDocumentSchema: RxJsonSchema<UserTagDocumentType> =
  userTagSchemaLiteral;

export type UserTagDocumentCollection = RxCollection<UserTagDocumentType>;
