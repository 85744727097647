import { format, parseISO } from 'date-fns';
import { BundleEntry, MergedFhirResource } from 'fhir/r2';
import { useEffect, useMemo, useState } from 'react';
import { EmptyRecordsPlaceholder } from '../components/EmptyRecordsPlaceholder';
import { useUser } from '../components/providers/UserProvider';
import { AppPage } from '../components/AppPage';
import { JumpToPanel } from '../components/timeline/JumpToPanel';
import { TimelineBanner } from '../components/timeline/TimelineBanner';
import { ClinicalDocument } from '../models/clinical-document/ClinicalDocument.type';
import { TimelineItem } from '../components/timeline/TimelineItem';
import { TimelineYearHeaderWrapper } from '../components/timeline/TimelineYearHeaderWrapper';
import { useDebounceCallback } from '@react-hook/debounce';
import { TimelineSkeleton } from './TimelineSkeleton';
import { useScrollToHash } from '../components/hooks/useScrollToHash';
import { SearchBar } from './SearchBar';
import { Transition } from '@headlessui/react';
import { TimelineTable } from '../components/timeline/TimelineTable';
import { Firestore, getDocs, query, where } from 'firebase/firestore';
import {
  collections,
  useFirestoreDb,
} from '../components/providers/FirebaseProvider';

/**
 * This should really be a background process that runs after every data sync instead of every view
 * @param db
 * @returns
 */
function fetchRecords(db: Firestore, user_id: string, queryStr?: string) {
  let q;
  if (queryStr?.trim() !== '') {
    q = query(
      collections.clinicalDocuments(db),
      where('user_id', '==', user_id),
      where('metadata.display_name', '>=', queryStr || ''),
      where('metadata.display_name', '<=', queryStr + '\uf8ff')
    );
  } else {
    q = query(
      collections.clinicalDocuments(db),
      where('user_id', '==', user_id)
    );
  }

  return getDocs(q).then((list) => {
    const lst = list;

    // Group records by date
    const groupedRecords: Record<
      string, // date to group by
      ClinicalDocument<BundleEntry<MergedFhirResource>>[] // documents/cards for date
    > = {};

    lst.docs.forEach((item) => {
      if (item.get('metadata')?.date === undefined) {
        console.warn('Date is undefined for object:');
        console.log(item.data());
      } else {
        const date = item.get('metadata')?.date
          ? format(parseISO(item.get('metadata')?.date), 'yyyy-MM-dd')
          : '-1';
        if (groupedRecords[date]) {
          groupedRecords[date].push(
            item.data() as ClinicalDocument<BundleEntry<MergedFhirResource>>
          );
        } else {
          groupedRecords[date] = [
            item.data() as ClinicalDocument<BundleEntry<MergedFhirResource>>,
          ];
        }
      }
    });

    console.debug(groupedRecords);

    return groupedRecords;
  });
}

export enum QueryStatus {
  IDLE,
  LOADING,
  SUCCESS,
  ERROR,
}

export function useRecordQuery(
  query: string
): [
  (
    | Record<string, ClinicalDocument<BundleEntry<MergedFhirResource>>[]>
    | undefined
  ),
  QueryStatus,
  boolean
] {
  console.log('query', query);
  const db = useFirestoreDb(),
    user = useUser(),
    [queryStatus, setQueryStatus] = useState(QueryStatus.IDLE),
    [initialized, setInitialized] = useState(false),
    [list, setList] =
      useState<
        Record<string, ClinicalDocument<BundleEntry<MergedFhirResource>>[]>
      >(),
    debounceExecQuery = useDebounceCallback((query) => {
      fetchRecords(db, user.id, query)
        .then((groupedRecords) => {
          setList(groupedRecords);
          console.debug(groupedRecords);
          setQueryStatus(QueryStatus.SUCCESS);
          setInitialized(true);
        })
        .catch((e) => {
          console.error(e);
          setQueryStatus(QueryStatus.ERROR);
        });
    }, 500);

  useEffect(() => {
    setQueryStatus(QueryStatus.LOADING);
    debounceExecQuery(query);
  }, [debounceExecQuery, query]);

  return [list, queryStatus, initialized];
}

export function TimelineTab() {
  const user = useUser(),
    [query, setQuery] = useState(''),
    [data, status, initialized] = useRecordQuery(query),
    hasNoRecords = query === '' && (!data || Object.entries(data).length === 0),
    hasRecords =
      (data !== undefined && Object.entries(data).length > 0) ||
      (query !== '' && data !== undefined);

  useScrollToHash();

  const listItems = useMemo(
    () =>
      data ? (
        <TimelineTable
          dateKey={'all'}
          itemList={Object.values(data).flatMap((itemList) => itemList)}
        />
      ) : (
        []
      ),
    [data]
  );

  return (
    <AppPage
      banner={
        <TimelineBanner
          image={
            user?.profile_picture?.data ? user.profile_picture.data : undefined
          }
          text={
            user?.first_name ? `Welcome back ${user.first_name}!` : 'Hello!'
          }
        />
      }
    >
      <Transition
        show={
          !initialized &&
          (status === QueryStatus.IDLE || status === QueryStatus.LOADING)
        }
        enter="transition-opacity ease-in-out duration-75"
        enterFrom="opacity-75"
        enterTo="opacity-100"
        leave="transition-opacity ease-in-out duration-75"
        leaveFrom="opacity-100"
        leaveTo="opacity-75"
      >
        <TimelineSkeleton />
      </Transition>
      <Transition
        as="div"
        className={'relative flex h-full'}
        show={initialized}
        enter="transition-opacity ease-in-out duration-75"
        enterFrom="opacity-75"
        enterTo="opacity-100"
        leave="transition-opacity ease-in-out duration-75"
        leaveFrom="opacity-100"
        leaveTo="opacity-75"
      >
        {hasNoRecords ? (
          <div className="mx-auto w-full max-w-4xl gap-x-4 px-4 pt-2 pb-4 sm:px-6 lg:px-8">
            <EmptyRecordsPlaceholder />
          </div>
        ) : null}
        {hasRecords ? (
          <div className="flex w-full">
            <JumpToPanel items={data} isLoading={false} />
            <div className="px-auto flex h-full max-h-full w-full justify-center overflow-y-scroll">
              <div className="flex h-full w-full max-w-4xl flex-col px-4 sm:px-6 lg:px-8">
                <SearchBar query={query} setQuery={setQuery} status={status} />
                {listItems}
                {hasNoRecords ? (
                  <p className="font-xl">{`No records found with query: ${query}`}</p>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}
      </Transition>
    </AppPage>
  );
}
