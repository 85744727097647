import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { PropsWithChildren } from 'react';
import {
  ConnectionDocument,
  ConnectionSources,
} from '../../models/connection-document/ConnectionDocument.type';
import * as Setu from '../../services/Setu';
import { from, Subject } from 'rxjs';
import { useNotificationDispatch } from './NotificationProvider';
import { differenceInDays, parseISO } from 'date-fns';
import Config from '../../environments/config.json';
import { useUserPreferences } from './UserPreferencesProvider';
import { useConnectionCards } from '../hooks/useConnectionCards';
import { useFirestoreDb } from './FirebaseProvider';
import { DocumentSnapshot, Firestore } from 'firebase/firestore';

type SyncJobProviderProps = PropsWithChildren<unknown>;

const SyncJobContext = React.createContext<
  Record<string, Subject<PromiseSettledResult<void[]>[]>>
>({});

const SyncJobDispatchContext = React.createContext<Dispatch | undefined>(
  undefined
);

type Action =
  | {
      type: 'add_job';
      id: string;
      connectionDocument: DocumentSnapshot<ConnectionDocument>;
      baseUrl: string;
      useProxy: boolean;
      db: Firestore;
    }
  | { type: 'remove_job'; id: string };

type Dispatch = (action: Action) => void;

const syncJobReducer: (
  state: Record<string, Subject<PromiseSettledResult<void[]>[]>>,
  action: Action
) => Record<string, Subject<PromiseSettledResult<void[]>[]>> = (
  state: Record<string, Subject<PromiseSettledResult<void[]>[]>>,
  action: Action
) => {
  switch (action.type) {
    case 'add_job': {
      const subject = new Subject<PromiseSettledResult<void[]>[]>();
      const observable = from(
        fetchMedicalRecords(
          action.connectionDocument,
          action.db,
          action.baseUrl,
          action.useProxy
        )
      );
      observable.subscribe(subject);
      return {
        ...state,
        [action.id]: subject,
      };
    }
    case 'remove_job': {
      const nState = { ...state };
      delete nState[action.id];
      return nState;
    }
    default: {
      throw new Error(`Unhandled action type: ${action}`);
    }
  }
};

export function SyncJobProvider(props: SyncJobProviderProps) {
  const [state, dispatch] = React.useReducer(
    syncJobReducer,
    {} as Record<string, Subject<PromiseSettledResult<void[]>[]>>
  );

  return (
    <SyncJobContext.Provider value={state}>
      <SyncJobDispatchContext.Provider value={dispatch}>
        <OnHandleUnsubscribeJobs>
          <HandleInitalSync>{props.children}</HandleInitalSync>
        </OnHandleUnsubscribeJobs>
      </SyncJobDispatchContext.Provider>
    </SyncJobContext.Provider>
  );
}

/**
 * Wrapping component that initiates a connection sync job for each connection card
 * if they have not been synced in the last day
 */
function HandleInitalSync({ children }: PropsWithChildren) {
  const sync = useSyncJobContext(),
    syncD = useSyncJobDispatchContext(),
    userPreferences = useUserPreferences(),
    list = useConnectionCards(),
    db = useFirestoreDb(),
    isDemo = Config.IS_DEMO === 'enabled',
    handleFetchData = useCallback(
      (item: DocumentSnapshot<ConnectionDocument>) => {
        if (syncD && userPreferences) {
          syncD({
            type: 'add_job',
            id: item.id,
            connectionDocument: item,
            baseUrl: item.get('location'),
            useProxy: userPreferences.use_proxy,
            db,
          });
        }
      },
      [db, syncD, userPreferences]
    ),
    hasRun = useRef(false),
    syncJobEntries = useMemo(() => new Set(Object.keys(sync)), [sync]);

  useEffect(() => {
    if (!isDemo) {
      if (list) {
        if (!hasRun.current && syncD) {
          for (const item of list) {
            if (
              !item.get('last_refreshed') ||
              (item.get('last_refreshed') &&
                Math.abs(
                  differenceInDays(
                    parseISO(item.get('last_refreshed')),
                    new Date()
                  )
                ) >= 1)
            ) {
              if (!syncJobEntries.has(item.get('id'))) {
                // Start sync, make sure this only runs once
                hasRun.current = true;
                // Add a delay to allow other parts of the app to load before starting sync
                setTimeout(
                  () => handleFetchData(item),
                  1000 + Math.ceil(Math.random() * 300)
                );
              }
            }
          }
        }
      }
    }
  }, [handleFetchData, isDemo, list, syncD, syncJobEntries]);

  return <>{children}</>;
}

/**
 * A wrapping component that handles removing sync jobs from the sync job context
 * once they are complete
 */
function OnHandleUnsubscribeJobs({ children }: PropsWithChildren) {
  const sync = useSyncJobContext(),
    syncD = useSyncJobDispatchContext(),
    notifyDispatch = useNotificationDispatch(),
    syncJobs = Object.entries(sync);

  useEffect(() => {
    syncJobs.forEach(([id, j]) => {
      j.subscribe({
        next(res) {
          const successRes = res.filter((i) => i.status === 'fulfilled');
          const errors = res.filter((i) => i.status === 'rejected');

          console.group('Sync Errors');
          errors.forEach((x) =>
            console.error((x as PromiseRejectedResult).reason)
          );
          console.groupEnd();

          if (errors.length === 0) {
            notifyDispatch({
              type: 'set_notification',
              message: `Successfully synced records`,
              variant: 'success',
            });
          } else {
            notifyDispatch({
              type: 'set_notification',
              message: `Some records were unable to be synced`,
              variant: 'info',
            });
          }
        },
        error(e: Error) {
          console.error(e);
          notifyDispatch({
            type: 'set_notification',
            message: `Error syncing records: ${e.message}`,
            variant: 'error',
          });
          if (syncD) {
            syncD({ type: 'remove_job', id });
          }
        },
        complete() {
          if (syncD) {
            syncD({ type: 'remove_job', id });
          }
        },
      });
    });
  }, [notifyDispatch, syncD, syncJobs]);

  return <>{children}</>;
}

export function useSyncJobContext() {
  const context = useContext(SyncJobContext);
  return context;
}

export function useSyncJobDispatchContext() {
  const context = useContext(SyncJobDispatchContext);
  return context;
}

async function fetchMedicalRecords(
  connectionDocument: DocumentSnapshot<ConnectionDocument>,
  db: Firestore,
  baseUrl: string,
  useProxy = false
) {
  switch (connectionDocument.get('source') as ConnectionSources) {
    case 'setu': {
      return await Setu.syncAllRecords(
        connectionDocument.data() as ConnectionDocument,
        db
      );
    }
    default: {
      throw Error(
        `Cannot sync unknown source: ${connectionDocument.get('source')}`
      );
    }
  }
}
